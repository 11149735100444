
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import GetLogsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetLogsResponse";
import GetLogRequest from "@/dataSource/api/LoggerMicroService/Services/Models/Request/LogGetterService/GetLogRequest";
import LogsItemResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/LogsItemResponse";
import getUTC from "@/function/getUTC"
import EndpointLogsItemResponse
  from '@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse'
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "content-logs-view-new",
  components: {
    TableView,
  },
})
export default class ContentLogsViewNew extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;

  innerFilter: GetLogRequest;
  items: LogsItemResponse[] = [];
  page: PageRequest = {Take: 20, Skip: 0};
  count = 0;
  noPagination = false;
  isLoading = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  onFilterChange() {
    this.page.Skip = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  mounted() {
    if (this.$route.name == "content-logs-view") {
      this.refresh();
    }
  }

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }
    let response: GetLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      LogType: this.filter.LogType,
      LogLevel: this.filter.LogLevel,
      Url: this.filter.Url,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: this.page.Skip, Take: 20},
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
      OnlyActiveRequests: this.filter.OnlyActiveRequests,
      LoggerName: this.filter.LoggerName,
      ShortMessage: this.filter.ShortMessage,
    };

    let tmpFilter = getUTC(this.innerFilter)

    try {
      response = await this.$api.LogGetterService.getLogsAsync(
          tmpFilter
      );
    } catch (error) {
      this.error.isSuccess = false
      this.error.errorMessage = error
      this.isLoading = false;
    }
    this.isLoading = false;
    this.count = response.Page.Count;
    this.page.Take = response.Page.Size;
    this.page.Skip = response.Page.Size * (response.Page.Current - 1);

    this.items = response.Items.map((item) => ({
      ProjectLogGroupId: item.ProjectLogGroupId,
      LogType: item.LogType,
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      Url: item.Url,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
      LoggerName: item.LoggerName,
      ShortMessage: item.ShortMessage,
    }));
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }

    this.page.Skip = skip;
    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    let el = this.items.find((item) => item.RequestId == id);
    localStorage.setItem("contentLogsRequestId", el.RequestId);
    this.$router.push({name: "logs-view-detail"});
    console.log(`el`, el);
  }
}
