<template>
  <div v-if="filter" class="logs-view">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <tirscript3-table
        :items="items"
        style="overflow-x: auto"
        template-columns="auto auto auto 190px 300px auto auto auto"
    >
      <template #header>
        <tirscript3-table-header-item>Дата</tirscript3-table-header-item>
        <tirscript3-table-header-item
        >Уровень<br/>Тип
        </tirscript3-table-header-item
        >
        <tirscript3-table-header-item>Url</tirscript3-table-header-item>
        <tirscript3-table-header-item
        >Размеры и время Request / Response/Time
        </tirscript3-table-header-item
        >
        <tirscript3-table-header-item
        >SessionToken RequestId ConnectionId
        </tirscript3-table-header-item
        >
        <tirscript3-table-header-item>UserId, Ip</tirscript3-table-header-item>
        <tirscript3-table-header-item
        >Logger name, <br/>
          Short message
        </tirscript3-table-header-item
        >
        <tirscript3-table-header-item>Действия</tirscript3-table-header-item>
      </template>
      <template #body="data">
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">{{
            $filters.DateFormat(data.item.DateStart)
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ $filters.LogLevel(data.item.LevelId) }}<br/>
          {{ $filters.LogType(data.item.LogType) }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">{{
            data.item.Url || "Нет"
          }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ data.item.RequestLength }}байт / {{ data.item.ResponseLength }}байт
          / {{ getIntervalTime(data.item.DateStart, data.item.DateEnd) }}ms
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="session-token table-item" :class="[type(data.item)]">
          {{ data.item.SessionToken }} <br/>
          {{ data.item.RequestId }} <br/>{{
            data.item.ConnectionId
          }}
        </tirscript3-table-body-item
        >
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]"
        >{{ data.item.UserId }} <br/>
          {{ data.item.Ip }}
        </tirscript3-table-body-item
        >

        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]"
        >{{ data.item.LoggerName }} <br/>
          {{ data.item.ShortMessage }}
        </tirscript3-table-body-item>

        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          <router-link
              :to="{
              name: 'content-logs-detail',
              params: { id: data.item.RequestId },
            }"
              class="detale"
          >
            Просмотр
          </router-link>
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>
    <div v-show="!noPagination && items && items.length > 0" class="panel">
      <div class="row align-items-center">
        <div class="col-auto ml-auto">
          <tirscript3-pagination
              ref="pagination"
              :count="count"
              :size="page.Take"
              class="mt-4"
              @onChange="onPaginationChanged"
          >
          </tirscript3-pagination>
        </div>
      </div>
    </div>
    <div
        v-if="!noPagination && items && items.length == 0 && isLoading == false"
        class="row"
    >
      <div class="pb-2 pt-4 col-12" style="text-align: center">Нет данных</div>
    </div>
    <div
        v-if="!error.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error.errorMessage }}
    </div>
  </div>
</template>
<script lang="ts">
import {Options, Prop, Vue, Watch} from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import moment from "moment";
import FilterModel from "../../components/filter-model";
import GetLogsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetLogsResponse";
import GetLogRequest from "@/dataSource/api/LoggerMicroService/Services/Models/Request/LogGetterService/GetLogRequest";
import LogsItemResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/LogsItemResponse";
import getUTC from "@/function/getUTC"
import EndpointLogsItemResponse
  from '@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse'
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "content-logs-view-new",
  components: {
    TableView,
  },
})
export default class ContentLogsViewNew extends Vue {
  @Prop({default: new FilterModel()}) filter: FilterModel;

  innerFilter: GetLogRequest;
  items: LogsItemResponse[] = [];
  page: PageRequest = {Take: 20, Skip: 0};
  count = 0;
  noPagination = false;
  isLoading = false;

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  onFilterChange() {
    this.page.Skip = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }

  mounted() {
    if (this.$route.name == "content-logs-view") {
      this.refresh();
    }
  }

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }
    let response: GetLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      LogType: this.filter.LogType,
      LogLevel: this.filter.LogLevel,
      Url: this.filter.Url,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      Page: {Skip: this.page.Skip, Take: 20},
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
      OnlyActiveRequests: this.filter.OnlyActiveRequests,
      LoggerName: this.filter.LoggerName,
      ShortMessage: this.filter.ShortMessage,
    };

    let tmpFilter = getUTC(this.innerFilter)

    try {
      response = await this.$api.LogGetterService.getLogsAsync(
          tmpFilter
      );
    } catch (error) {
      this.error.isSuccess = false
      this.error.errorMessage = error
      this.isLoading = false;
    }
    this.isLoading = false;
    this.count = response.Page.Count;
    this.page.Take = response.Page.Size;
    this.page.Skip = response.Page.Size * (response.Page.Current - 1);

    this.items = response.Items.map((item) => ({
      ProjectLogGroupId: item.ProjectLogGroupId,
      LogType: item.LogType,
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      Url: item.Url,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
      LoggerName: item.LoggerName,
      ShortMessage: item.ShortMessage,
    }));
  }

  //событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.page.Take;
    if (this.page.Skip === skip) {
      return;
    }

    this.page.Skip = skip;
    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  onReadMore(id: string) {
    let el = this.items.find((item) => item.RequestId == id);
    localStorage.setItem("contentLogsRequestId", el.RequestId);
    this.$router.push({name: "logs-view-detail"});
    console.log(`el`, el);
  }
}
</script>

<style lang="less">
.logs-view {
  .detale {
    color: blue;
    cursor: pointer;
  }
}

.logs-view .tirscript3-table .tirscript3-table-header-item,
.logs-view .tirscript3-table .tirscript3-table-body-item {
  justify-content: center;
  text-align: center;
}

.session-token {
  font-family: monospace;
  font-size: 13px !important;
}
</style>
<style scoped>
.table-item.error {
  background: #FED6BC;
}
.table-item.warn {
  background: #FDEED9;
}
</style>